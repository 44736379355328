import { setContext, getContext } from 'svelte'
import { writable } from 'svelte/store'
import type { Writable } from 'svelte/store'

export type Roof = 'null' | '0' | '5' | '10' | '15' | '20' | '30' | '40' | '50'
export type Facade = 'null' | '0' | '5' | '10' | '15' | '20' | '25' | '30'
export type Window = 'null' | 'WOODEN' | 'DOUBLE' | 'TRIPLE'

type HouseHeatLossModal = {
	value?: number | null
	valueType?: 'LOW' | 'MEDIUM' | 'HIGH' | 'CALCULATED'
	formData?: {
		roof: Roof
		facade: Facade
		window: Window
		zip: string
		floorSquare: string
	} | null
	calculate: ({
		roof,
		facade,
		window,
		floorSquare,
		zip
	}: {
		roof: Roof
		facade: Facade
		window: Window
		floorSquare: string
		zip: string
	}) => Promise<number | null>
}

const defaults: HouseHeatLossModal = {
	value: null,
	valueType: 'LOW',
	formData: null,
	calculate: async ({ roof, facade, window, floorSquare, zip }) => {
		const response = await fetch('/api/house-heat-loss', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				zip: zip || '12000',
				floorSquare: floorSquare || 120,
				window: window,
				roof: roof,
				facade: facade
			})
		})
		const data: { heatLoss: number | null } = await response.json()
		return data.heatLoss || null
	}
}

const HEAT_LOSS_MODAL_CTX = 'HEAT_LOSS_MODAL_CTX'
const HEAT_LOSS_MODAL_OPEN_CTX = 'HEAT_LOSS_MODAL_OPEN_CTX'

export function setHeatLossModalState(data?: HouseHeatLossModal) {
	const state = writable<HouseHeatLossModal>({
		...defaults,
		...(data && { ...data })
	})
	setContext(HEAT_LOSS_MODAL_CTX, state)
	return state
}

export function getHeatLossModalState() {
	return getContext<Writable<HouseHeatLossModal>>(HEAT_LOSS_MODAL_CTX)
}

export function setHeatLossModalOpenState(value: boolean) {
	const state = writable<boolean>(value)
	setContext(HEAT_LOSS_MODAL_OPEN_CTX, state)
	return state
}

export function getHeatLossModalOpenState() {
	return getContext<Writable<boolean>>(HEAT_LOSS_MODAL_OPEN_CTX)
}
